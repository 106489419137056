<template>
  <!-- <Breadcrumb :items="breadcrumb" /> -->
  <div class="warehouse-detail">
    <!-- <h1
      class="d-inline grey lighten-3 px-2"
      v-html="$t('warehouse.detail.title', { warehouse: warehouse.name })"
    ></h1> -->

    <!-- <v-divider class="custom-divider"></v-divider> -->
    <v-container>
      <router-link
        v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
        :to="{ path: $routerHistory.previous().path }"
        class="d-flex align-center py-2 mb-6 go-back font-weight-bold"
        aria-label="Torna Indietro"
      >
        <v-icon color="primary">$chevronLeft</v-icon>
        Torna indietro
      </router-link>
      <flyer-container v-if="flyerId" :sf_flyer_id="flyerId" :list="false" />
    </v-container>
  </div>
</template>

<script>
import clickHandler from "~/mixins/clickHandler";
import FlyerContainer from "@/components/shopfully/FlyerContainer.vue";

export default {
  name: "WarehouseFlyer",
  components: { FlyerContainer },
  data() {
    return {
      flyerId: null
    };
  },
  mixins: [clickHandler],
  mounted() {
    this.flyerId = this.$route.params.flyerId;
  },
  watch: {
    $route() {
      this.flyerId = this.$route.params.flyerId;
    }
  }
};
</script>
